<template>
  <model title="Collapse" tag="折叠面板">
    <h3>基础用法</h3>
    <lh-collapse>
      <div slot="title">折叠面板标题</div>
      <div>折叠面板内容...</div>
      <div>折叠面板内容...</div>
      <div>折叠面板内容...</div>
      <div>折叠面板内容...</div>
      <div>折叠面板内容...</div>
      <div>折叠面板内容...</div>
      <div>折叠面板内容...</div>
    </lh-collapse>
    <div>
      <lh-collapse>
        <div slot="title">示例代码</div>
        <lh-code-editor v-model="code1" :readonly="true"></lh-code-editor>
      </lh-collapse>
    </div>
    <h3>属性参考表</h3>
    <lh-table>
      <lh-tr>
        <lh-th>参数</lh-th>
        <lh-th>说明</lh-th>
        <lh-th>类型</lh-th>
        <lh-th>可选值</lh-th>
        <lh-th>默认值</lh-th>
      </lh-tr>
      <lh-tr>
        <lh-td>width</lh-td>
        <lh-td>折叠面板宽度</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>auto</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>contentPadding</lh-td>
        <lh-td>折叠面板内容的内边距</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>6px</lh-td>
      </lh-tr>
      <lh-tr>
        <lh-td>margin</lh-td>
        <lh-td>组件外边距</lh-td>
        <lh-td>String</lh-td>
        <lh-td>-</lh-td>
        <lh-td>5px</lh-td>
      </lh-tr>
    </lh-table>
  </model>
</template>

<script>
import Model from '../../components/Model.vue'
export default {
  components: { Model },
  name:"CollapseView",
  data(){
    return {
      code1:
        "<template>\n"+
          "<lh-collapse>\n"+
          "  <div slot=\"title\">折叠面板标题</div>\n"+
          "  <div>折叠面板内容...</div>\n"+
          "  <div>折叠面板内容...</div>\n"+
          "  <div>折叠面板内容...</div>\n"+
          "  <div>折叠面板内容...</div>\n"+
          "  <div>折叠面板内容...</div>\n"+
          "  <div>折叠面板内容...</div>\n"+
          "  <div>折叠面板内容...</div>\n"+
          "</lh-collapse>\n"+
        "</template>"
    }
  }
}
</script>

<style>
</style>